import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then((m) => m.GroupsPageModule),
  },
  {
    path: 'groups/:groupid',
    loadChildren: () => import('./pages/messages/messages.module').then((m) => m.MessagesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.module').then( m => m.CustomersPageModule)
  },
  {
    path: 'doctors',
    loadChildren: () => import('./pages/doctors/doctors.module').then( m => m.DoctorsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'customer/:id',
    loadChildren: () => import('./pages/customer/customer.module').then( m => m.CustomerPageModule)
  },
  {
    path: 'patienten',
    loadChildren: () => import('./pages/patienten/patienten.module').then( m => m.PatientenPageModule)
  },
  {
    path: 'patient/:pkPatient',
    loadChildren: () => import('./pages/patient/patient.module').then( m => m.PatientPageModule)
  },
  {
    path: 'auftraege',
    loadChildren: () => import('./pages/auftraege/auftraege.module').then( m => m.AuftraegePageModule)
  },
  {
    path: 'load-csv',
    loadChildren: () => import('./pages/load-csv/load-csv.module').then( m => m.LoadCSVPageModule)
  },
  {
    path: 'patient-auftrag/:pkAuftrag',
    loadChildren: () => import('./pages/patient-auftrag/patient-auftrag.module').then( m => m.PatientAuftragPageModule)
  },
  {
    path: 'arzt/:pkArzt',
    loadChildren: () => import('./pages/arzt/arzt.module').then( m => m.ArztPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'show-documentation',
    loadChildren: () => import('./pages/show-documentation/show-documentation.module').then( m => m.ShowDocumentationPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
